* {
  margin: 0;
  padding: 0;
}
html,
body {
  overflow: hidden;
  font-family: europa,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 10000;
  color: #D8D8D8;
}
h1.title {
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: .75rem;
}
.subtitle {
  font-weight: 100;
  letter-spacing: 0;
}
audio {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
  z-index: 10000;
  color: #D8D8D8;
}
.label {
  color: #FFF;
  padding: 2px;
  z-index: 10000;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 10000;
  color: #D8D8D8;
  font-size: 80%;
}